import { getBuses, addBus, updateBus, addBusOnRoute } from '../../api/bus.api'
import { getToken } from '../../helpers/session'

export const GET_BUS_LIST = 'GET_BUS_LIST'
export const GET_BUS = 'GET_BUS'
export const ADD_BUS = 'ADD_BUS'
export const REMOVE_BUS = 'REMOVE_BUS'
export const UPDATE_BUS = 'UPDATE_BUS'
export const ADD_BUS_ON_ROUTE = 'ADD_BUS_ON_ROUTE'
const START_LOADING = 'START_LOADING'
const END_LOADING = 'END_LOADING'

const state = () => ({
  buses: [],
  bus: {},
  loading: false,
  pageSize: 20,
  totalCount: 0
})

const getters = {
  getBuses: (state) => state.buses,
  getBus: (state) => state.bus,
  getBusesTotalCount: (state) => state.totalCount,
  getBusesPageSize: (state) => state.pageSize,
  getBusesLoading: (state) => state.loading
}

const actions = {
  [GET_BUS_LIST]: async ({ commit }, params) => {
    commit(START_LOADING)
    try {
      const response = await getBuses(params, getToken())
      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      } else {
        commit(GET_BUS_LIST, response)
      }
      return response
    } catch (ex) {
      console.error(ex)
    } finally {
      commit(END_LOADING)
    }
  },
  [GET_BUS]: async ({ commit, state }, id = '') => {
    if (state.buses.length) {
      commit(GET_BUS, { id })
    } else {
      commit(START_LOADING)

      try {
        const response = await getBuses({}, getToken())

        if (response.Message || response.message) {
          throw Error(response && (response.Message || response.message) || '')
        } else {
          commit(GET_BUS, { id, response })
        }

        return true
      } catch (ex) {
        console.error(ex)
      } finally {
        commit(END_LOADING)
      }
    }
  },
  [ADD_BUS]: async ({ commit }, payload) => {
    try {
      commit(START_LOADING)
      const response = await addBus(payload, getToken())

      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      }

      return response
    } 
    // catch (ex) {
    //   throw Error(ex)
    // }
     finally {
      commit(END_LOADING)
    }
  },
  [ADD_BUS_ON_ROUTE]: async ({ commit }, payload) => {
    try {
      commit(START_LOADING)
      const response = await addBusOnRoute(payload, getToken())
      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      }
      return response
    }
    // catch (ex) {
    //   throw Error(ex)
    // }
    finally {
      commit(END_LOADING)
    }
  },
  [UPDATE_BUS]: async ({ commit }, payload) => {
    try {
      commit(START_LOADING)
      const response = await updateBus(payload, getToken())
      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      }
      return response
    } 
    // catch (ex) {
    //   throw Error(ex)
    // }
     finally {
      commit(END_LOADING)
    }
  }
}

const mutations = {
  [GET_BUS_LIST]: (state, response) => {
    state.buses = response.data
    state.totalCount = response.totalCount
  },
  [GET_BUS]: (state, { id, response } = {}) => {
    state.bus = (response && response.data || state.buses).find(item => Number(item.id) === Number(id))
  },
  [START_LOADING]: (state) => {
    state.loading = true
  },
  [END_LOADING]: (state) => {
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
