import { getBusColors } from '../../../api/dics.api'
import { getToken } from '../../../helpers/session'

export const GET_BUS_COLORS_LIST = 'GET_BUS_COLORS_LIST'
const START_LOADING = 'START_LOADING'
const END_LOADING = 'END_LOADING'

const state = () => ({
  colors: [],
  loading: false
})

const getters = {
  getBusColors: (state) => state.colors,
  getBusColorsLoading: (state) => state.loading
}

const actions = {
  [GET_BUS_COLORS_LIST]: async ({ commit }) => {
    commit(START_LOADING)

    try {
      const response = await getBusColors(getToken())
      commit(GET_BUS_COLORS_LIST, response)

      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      } else {
        commit(GET_BUS_COLORS_LIST, response)
      }

      return response
    } catch (ex) {
      console.error(ex)
    } finally {
      commit(END_LOADING)
    }
  }
}

const mutations = {
  [GET_BUS_COLORS_LIST]: (state, colors) => {
    state.colors = colors
  },
  [START_LOADING]: (state) => {
    state.loading = true
  },
  [END_LOADING]: (state) => {
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
