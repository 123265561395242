import { getToken, setToken, removeToken } from '../../helpers/session'
import { authUser } from '../../api/auth.api'
import { createError, mapErrorsByCode } from "../../helpers/errors";

export const GET_USER = 'GET_USER'
export const AUTH_USER = 'AUTH_USER'
export const EXIT_USER = 'EXIT_USER'

const state = () => ({
  user: {},
  loading: false,
  token: getToken() || '',
})

const getters = {
  getProfile: (state) => state.user,
  isAuthenticated: state => !!state.token
}

const actions = {
  [AUTH_USER]: async ({ commit }, { login, password }) => {
    try {
      const { access_token, ...res } = await authUser({ userName: login, password });
      
      if (access_token) {
        commit(AUTH_USER, access_token);
        return access_token;
      } else {
        if (res.code == 401) {
          throw createError('UNAUTHORIZED');
        }
        throw Error(res && (res.Message || res.message || res.title || mapErrorsByCode('UNKNOWN')));
      }
    } catch (ex) {
      if (ex.code === 'UNAUTHORIZED') {
        throw createError('PASSWORD_MISMATCH');
      }
      throw ex;
    }
  },
  [EXIT_USER]: ({ commit }) => {
    commit(EXIT_USER)
  }
}

const mutations = {
  [AUTH_USER]: (state, token) => {
    setToken(token)
    state.token = token
  },
  [EXIT_USER]: (state) => {
    removeToken()
    state.token = ''
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
