import { ACCESS_TOKEN, REFRESH_TOKEN, EXPIRY_AT } from '../constants/app';

export const getToken = () => localStorage.getItem(ACCESS_TOKEN);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

export const setToken = token => localStorage.setItem(ACCESS_TOKEN, token);

export const setRefreshToken = token => localStorage.setItem(REFRESH_TOKEN, token);

export const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(EXPIRY_AT);
};

export const getAuthHeader = () => `Bearer ${getToken()}`;
