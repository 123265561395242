import { DEFAULT_ERRORS } from '../constants/errors'

/**
 * Create the Error object
 * @param {String} code - Error code to look in the object passing in 'errs'
 */
export const createError = (code, errs = DEFAULT_ERRORS, noteText = null) => {
  const errorObject = new Error(mapErrorsByCode(code, errs) + (noteText ? ` (${noteText})` : ''));
  errorObject.name = code;
  errorObject.code = code;
  return errorObject;
}

/**
 * Get Error text
 * @param {String} code - text code of error
 */
export const mapErrorsByCode = (code, errs = DEFAULT_ERRORS) => {
  //const codeText = code.split('Error: ')
  //return (codeText && codeText[1] && errs[codeText[1]]) || errs[code] || 'Произошла непредвиденная ошибка'
  return (code && errs[code]) || errs['UNKNOWN'] || 'Непредвиденная ошибка';
}

