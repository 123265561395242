import { GET } from './base'

export const getBusColors = (token = null) => {
  return GET('dics/buscolors', {}, token)
}

export const getBusModels = (token = null) => {
  return GET('dics/busmodels', {}, token)
}

export const getAllRoutes = (token = null) => {
  return GET('routes/all', {}, token)
}

export const getDirection = (token = null) => {
  return GET('dics/scheduleDirections', {}, token)
}

export const getStops = (token = null) => {
  return GET('dics/busstops', {}, token)
}
