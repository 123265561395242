import { createQueryString } from '../helpers/url'
import { GET, PATCH } from './base'

export const getRoutes = ({ stateCode } = {}, token = null) => {
  return GET(`routes${createQueryString({ stateCode })}`, {}, token)
}

export const getRoute = ({id} = {}, token = null) => {
  return GET(`routes/${id}`, {}, token)
}

export const updateStops = ({id, dir, ...rest} = {}, token = null) => {
  return PATCH(`routes/${id}/busstops/${dir}`, rest.stops, token)
}

export const updatePolylines = ({id, dir, ...rest} = {}, token = null) => {
  return PATCH(`routes/${id}/polylines/${dir}`, rest.polyline, token)
}
