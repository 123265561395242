import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticClass:"bg-image"},[_c(VAppBar,{attrs:{"color":_vm.color,"dense":"","dark":"","app":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.getCompany.fullName ? _vm.getCompany.fullName + ' - ' : '')+" Кабинет перевозчика")]),_c(VSpacer),_c(VMenu,{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[(_vm.isAuth)?_c(VListItem,{on:{"click":_vm.exit}},[_c(VListItemTitle,[_vm._v("Выход")])],1):_vm._e()],1)],1)],1),_c(VMain,[(_vm.isDrawerDisplay && _vm.isAuth)?_c(VNavigationDrawer,{attrs:{"color":_vm.color,"expand-on-hover":_vm.expandOnHover,"mini-variant":false,"right":_vm.right,"permanent":_vm.permanent,"absolute":"","dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{staticClass:"py-0 offset-top",attrs:{"dense":"","nav":""}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"to":item.route,"link":""},on:{"click":function($event){_vm.drawer = !_vm.isDrawerDisplay}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1):_vm._e(),_c('div',{class:{ content: true }},[_c(VContainer,[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }