import { getRoutes, getRoute, updateStops, updatePolylines } from '../../api/route.api'
import { getToken } from '../../helpers/session'

export const GET_ROUTE_LIST = 'GET_ROUTE_LIST'
export const GET_ROUTE = 'GET_ROUTE'
export const ADD_ROUTE = 'ADD_ROUTE'
export const UPDATE_POLYLINES = 'UPDATE_POLYLINES'
export const UPDATE_STOPS = 'UPDATE_STOPS'
const START_LOADING = 'START_LOADING'
const END_LOADING = 'END_LOADING'

const state = () => ({
  routes: [],
  route: {},
  loading: false,
  pageSize: 20,
  totalCount: 0
})

const getters = {
  getRoutes: (state) => state.routes,
  getRoute: (state) => state.route,
  getRoutesPageSize: (state) => state.pageSize,
  getRoutesTotalCount: (state) => state.totalCount,
  getRoutesLoading: (state) => state.loading
}

const actions = {
  [GET_ROUTE_LIST]: async ({ commit }, params) => {
    commit(START_LOADING)

    try {
      const response = await getRoutes({ ...params, pageSize: 20 }, getToken())

      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      } else {
        commit(GET_ROUTE_LIST, response)
      }

      return response
    } catch (ex) {
      console.error(ex)
    } finally {
      commit(END_LOADING)
    }
  },
  [GET_ROUTE]: async ({ commit }, id = '') => {
    commit(START_LOADING)

    try {
    const response = await getRoute({id}, getToken())
    commit(GET_ROUTE, response)

    return true
    } catch (ex) {
      console.error(ex)
    } finally {
      commit(END_LOADING)
    }    
  },
  [UPDATE_POLYLINES]: async ({ commit }, payload) => {
    try {
      commit(START_LOADING)
      const response = await updatePolylines(payload, getToken())

      return response
    } catch (ex) {
      throw Error(ex)
    } finally {
      commit(END_LOADING)
    }
  },
  [UPDATE_STOPS]: async ({ commit }, payload) => {
    try {
      commit(START_LOADING)
      const response = await updateStops(payload, getToken())

      return response
    } catch (ex) {
      throw Error(ex)
    } finally {
      commit(END_LOADING)
    }
  }
}

const mutations = {
  [GET_ROUTE_LIST]: (state, response) => {
    state.routes = response.data
    state.totalCount = response.totalCount
  },
  [GET_ROUTE]: (state, route = {}) => {
    state.route = route
  },
  [START_LOADING]: (state) => {
    state.loading = true
  },
  [END_LOADING]: (state) => {
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
