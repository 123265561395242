import { getStops } from '../../../api/dics.api'
import { getToken } from '../../../helpers/session'

export const GET_STOPS_LIST = 'GET_STOPS_LIST'
const START_LOADING = 'START_LOADING'
const END_LOADING = 'END_LOADING'

const state = () => ({
  stops: [],
  loading: false,
  pages: 0
})

const getters = {
  getStops: (state) => state.stops,
  getStopsPages: (state) => state.pages,
  getStopsLoading: (state) => state.loading
}

const actions = {
  [GET_STOPS_LIST]: async ({ commit }) => {
    commit(START_LOADING)

    try {
      const response = await getStops(getToken())
      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      } else {
        commit(GET_STOPS_LIST, response)
      }
      return response
    } catch (ex) {
      console.error(ex)
    } finally {
      commit(END_LOADING)
    }
  }
}

const mutations = {
  [GET_STOPS_LIST]: (state, stops) => {
    state.stops = []
    state.stops = stops
  },
  [START_LOADING]: (state) => {
    state.loading = true
  },
  [END_LOADING]: (state) => {
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
