import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
//import colors from 'vuetify/es5/util/colors'
import ru from 'vuetify/src/locale/ru.ts'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { ru },
      current: 'ru'
    },
    theme: {
        dark: false,
        themes: {
            light: {
              primary: colors.green.base,
              secondary: colors.deepOrange.base,
              accent: colors.blue.base,
              error: colors.red.darken4,
              warning: colors.orange.darken2,
              info: colors.blueGrey.darken2,
              success: colors.lightGreen.darken4,
              anchor: '#ff0000'
            },
          },
      },
});
