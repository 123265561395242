import {getCompany} from '../../api/company'
import {getToken} from '../../helpers/session'

export const GET_COMPANY = 'GET_COMPANY'
const START_LOADING = 'START_LOADING'
const END_LOADING = 'END_LOADING'
export const EXIT_COMPANY = 'EXIT_COMPANY'

const state = () => ({
    company: '',
    loading: false
})

const getters = {
    getCompany: (state) => state.company,
    getCompanyLoading: (state) => state.loading
}

const actions = {
    [GET_COMPANY]: async ({commit}) => {
        try {
            const response = await getCompany(getToken())
            console.log(response);
            if (response.Message || response.message) {
                throw Error(response && (response.Message || response.message) || '')
            } else {
                commit(GET_COMPANY, response)
            }
            commit(GET_COMPANY, response)
            return response
        } catch (e) {
            console.error(e)
        }
    },
    [EXIT_COMPANY]: ({ commit }) => {
        commit(EXIT_COMPANY)
    }
}

const mutations = {
    [GET_COMPANY]: (state, company) => {
        state.company = company;
    },
    [START_LOADING]: (state) => {
        state.loading = true;
    },
    [END_LOADING]: (state) => {
        state.loading = false;
    },
    [EXIT_COMPANY]: (state) => {
        state.company = ''
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
