import { createQueryString } from '../helpers/url'
import { GET, POST, PUT } from './base'

export const getBuses = (query = {}, token = null) => {
  return GET(`buses${createQueryString(query)}`, {}, token)
}

// Not allowed
export const getBus = (id, token = null) => {
  return GET(`buses/${id}`, {}, token)
}

export const addBus = (data, token = null) => {
  return POST('buses', data, token)
}

export const updateBus = ({id, ...rest} = {}, token = null) => {
  return PUT(`buses/${id}`, rest, token)
}

export const addBusOnRoute = ({busId, routeId} = {}, token = null) => {
  return POST(`buses/${busId}/routes/${routeId}`, {}, token)
}
