import { getToken } from '../../helpers/session'
import { getTransactionsGrouped } from '../../api/transaction.api'

export const GET_TRANSACTIONS_GROUPED = 'GET_TRANSACTIONS_GROUPED'

const START_LOADING = 'START_LOADING'
const END_LOADING = 'END_LOADING'

const state = () => ({
  transactionsGrouped: [],
  loading: false
})

const getters = {
  getTransactionsGrouped: (state) => state.transactionsGrouped,
  getTransactionsLoading: (state) => state.loading
}

const actions = {
  [GET_TRANSACTIONS_GROUPED]: async ({ commit }, payload) => {
    commit(START_LOADING)

    try {
      console.log(payload)
      const response = await getTransactionsGrouped(payload, getToken())
      console.log(response)

      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      } else {
        commit(GET_TRANSACTIONS_GROUPED, response)
      }

      return response
    } catch (ex) {
      console.error(ex)
    } finally {
      commit(END_LOADING)
    }
  },
  
}

const mutations = {
  [GET_TRANSACTIONS_GROUPED]: (state, transactions) => {
    state.transactionsGrouped = transactions
  },
  [START_LOADING]: (state) => {
    state.loading = true
  },
  [END_LOADING]: (state) => {
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
