import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import bus from './modules/bus'
import route from './modules/route'
import stops from './modules/dics/stops'
import busmodels from './modules/dics/busmodels'
import buscolors from './modules/dics/buscolors'
import allRoutes from './modules/dics/allRoutes'
import direction from './modules/dics/direction'
import transactions from './modules/transactions'
import company from './modules/company'
import detailed from './modules/detailed'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        user,
        bus,
        route,
        stops,
        busmodels,
        buscolors,
        allRoutes,
        direction,
        transactions,
        company,
        detailed,
    },
    strict: debug,
})
