import { getToken } from '../../helpers/session'
import { getDetailedData } from '../../api/detailed.api'

export const GET_DETAILED_DATA = 'GET_DETAILED_DATA'

const START_LOADING = 'START_LOADING'
const END_LOADING = 'END_LOADING'

const state = () => ({
  detailedData: [],
  detailedDataTotalCount: 0,
  loading: false
})

const getters = {
  getDetailedData: (state) => state.detailedData,
  getDetailedDataTotalCount: (state) => state.detailedDataTotalCount,
  getDetailedDataLoading: (state) => state.loading
}

const actions = {
  [GET_DETAILED_DATA]: async ({ commit }, payload) => {
    commit(START_LOADING)

    try {
      const response = await getDetailedData(payload.timestamp, payload.payload, getToken())

      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      } else {
        commit(GET_DETAILED_DATA, response)
        console.log(response)
      }

      return response
    } catch (ex) {
      console.error(ex)
    } finally {
      commit(END_LOADING)
    }
  },
  
}

const mutations = {
  [GET_DETAILED_DATA]: (state, detailedData) => {
    state.detailedData = detailedData
    state.detailedDataTotalCount = detailedData.totalCount
  },
  [START_LOADING]: (state) => {
    state.loading = true
    state.detailedData = []
    state.detailedDataTotalCount = 0
  },
  [END_LOADING]: (state) => {
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
