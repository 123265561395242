// base api realization

import logger from '../helpers/logger';
import { API_URL_TRANSACTIONS } from '../constants/env';
import { removeToken } from '../helpers/session';
import { createError } from '../helpers/errors';
import { DEFAULT_ERRORS } from '../constants/errors';

const TIMEOUT = 15 * 1000; // 15 sec timeout
const baseUrl = API_URL_TRANSACTIONS

const fetchWithTimeout = async (...args) => {
  const t = setTimeout(() => {
    throw createError('TIMEOUT');
  }, TIMEOUT);

  try {
    return await fetch(...args);
  }
  catch (ex) {
    logger.error(ex.toString());
    throw createError('CONNECTION_REFUSED');
  }
  finally {
    clearTimeout(t);
  }
}

const authHeaders = token => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Accept', 'application/json');
  return headers;
};

// /**
//  * Throws connection error.
//  */
// const throwConnectionError = (error) => {
//   throw new Error(error);
// }

const BaseAPI = async (method, { url, body, token }, mockResponse) => {
  const baseHeaders = new Headers();
  baseHeaders.append('Content-Type', 'application/json');
  baseHeaders.append('Accept', 'application/json');
  const prodUrl = process.env.NODE_ENV === 'production' ? url.replace('transactions/', '') : url
  const uri = `${baseUrl}/${prodUrl}`;

  const params = {
    method,
    headers: token ? authHeaders(token) : baseHeaders,
  };

  if (method !== 'GET') {
    params.body = JSON.stringify(body)
  }

  logger.info(`${method}: `, uri, params);

  if (mockResponse) {
    //return new Promise((resolve) => resolve(mockResponse))
    return mockResponse;
  }

  // try {
  // }
  // catch (error) {
  //   //response = throwConnectionError(error);
  //   throw error;
  // }

  // fetch или fetchWithTimeout возвращают response, который содержит всю информацию об ответе, а не только тело ответа
  let response = await fetchWithTimeout(uri, params);
  validateResponse(response);
  return response.json();
}

export const POST = (url, body, token = null, mockResponse = undefined) => {
  return BaseAPI('POST', { url, body, token }, mockResponse);
}

export const PUT = (url, body, token = null, mockResponse = undefined) => {
  return BaseAPI('PUT', { url, body, token }, mockResponse);
}

export const PATCH = (url, body, token = null, mockResponse = undefined) => {
  return BaseAPI('PATCH', { url, body, token }, mockResponse);
}

export const GET = (url, body, token = null, mockResponse = undefined) => {
  return BaseAPI('GET', { url, body, token }, mockResponse);
}

export const DELETE = (url, body, token = null, mockResponse = undefined) => {
  return BaseAPI('DELETE', { url, body, token }, mockResponse);
}

/**
* Validates HTTP response and throws error if something goes wrong.
* @param {Response} response
*/
const validateResponse = (response) => {
  if (response.status === 200 && !response.error) {
    return;
  }
  else if (response.status === 401) {
    removeToken();
    window.location.reload();
    //throw createError('UNAUTHORIZED');
  }
  else if (response.status === 403) {
    logger.error(response);
    throw createError('FORBIDDEN');
  }
  else if (response.status === 404) {
    logger.error(response);
    throw createError('NOT_FOUND');
  }
  else if (response.status >= 400 && response.status <= 499) {
    logger.error(response);
    throw createError('HTTP_4xx', DEFAULT_ERRORS, 'HTTP:' + response.status);
  }
  else if (response.status >= 500 && response.status <= 599) {
    logger.error(response);
    throw createError('HTTP_5xx', DEFAULT_ERRORS, 'HTTP:' + response.status);
  }
  else if (response.status !== 200 || response.error) {
    logger.error(response);
    throw createError('HTTP_xxx', DEFAULT_ERRORS, 'HTTP:' + response.status);
  }

  logger.error(response);
  throw createError('HTTP_xxx');

  // if (__DEV__ && typeof response.clone === 'function') {
  //   const clonedResponse = response.clone();
  //   const body = await clonedResponse.json();

  //   logger.info({
  //     response,
  //     body,
  //   });
  // } else {
  //   logger.info(response);
  // }

  // return Promise.resolve(true);
}
