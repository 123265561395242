import { getDirection } from '../../../api/dics.api'
import { getToken } from '../../../helpers/session'

export const GET_DIRECTION = 'GET_DIRECTION'
const START_LOADING = 'START_LOADING'
const END_LOADING = 'END_LOADING'

const state = () => ({
  direction: [],
  loading: false
})

const getters = {
  getDirection: (state) => state.direction,
  getDirectionLoading: (state) => state.loading
}

const actions = {
  [GET_DIRECTION]: async ({ commit }) => {
    commit(START_LOADING)

    try {
      const response = await getDirection(getToken())

      if (response.Message || response.message) {
        throw Error(response && (response.Message || response.message) || '')
      } else {
        commit(GET_DIRECTION, response)
      }

      return response
    } catch (ex) {
      console.error(ex)
    } finally {
      commit(END_LOADING)
    }
  }
}

const mutations = {
  [GET_DIRECTION]: (state, direction) => {
    state.direction = direction
  },
  [START_LOADING]: (state) => {
    state.loading = true
  },
  [END_LOADING]: (state) => {
    state.loading = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
