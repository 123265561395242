export const DEFAULT_ERRORS = {
  HTTP_4xx: 'Ошибка формирования запроса для сервера, обратитесь к администратору системы',
  HTTP_5xx: 'Ошибка выполнения запроса на сервере, обратитесь к администратору системы',
  HTTP_xxx: 'Ошибка выполнения запроса, обратитесь к администратору системы',
  TIMEOUT: 'Истекло время ожидания ответа от сервера, повторите пожалуйста позже',
  UNAUTHORIZED: 'Вы не авторизованы в системе',
  FORBIDDEN: 'Доступ к запрашиваемой функции запрещен',
  NOT_FOUND: 'Запрашиваемый ресурс отсутствует на сервере, возмонжо, он был удален или перемещен',
  PASSWORD_MISMATCH: 'Не верный логин или пароль',
  SUCCESS_SAVED: 'Успешно сохранено',
  USER_AUTHED: 'Успешно авторизован',
  USER_CANT: 'Вы не можете авторизоваться',
  CONNECTION_REFUSED: 'Нет связи с сервером',
  UNKNOWN: 'Произошла непредвиденная ошибка',
}

// export const LOGIN_ERRORS = {
//   PASSWORD_MISMATCH: 'Не верный логин или пароль'
// }
