import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'
import ROUTES from './constants/routes'
// import { BUS_ROUTE, AUTH_ROUTE, ROUTES_ROUTE, TRANSACTIONS_ROUTE } from './constants/routes'

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next(ROUTES.ROOT);
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next(`${ROUTES.AUTH}?redirect=${to.fullPath}`);
};

export default new Router({
  mode: 'hash',
  routes: [
    {
      name: 'login',
      path: ROUTES.AUTH,
      component: () => import(/* */ './Pages/Auth.vue'),
      beforeEnter: ifNotAuthenticated
    },
    {
      name: 'root',
      path: ROUTES.ROOT,
      component: () => import(/* */ './Pages/Root.vue'),
      beforeEnter: ifAuthenticated
    }, {
      name: 'routes',
      path: ROUTES.ROUTES,
      component: () => import(/* */ './Pages/Routes/Routes.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'route',
      path: `${ROUTES.ROUTES}/:id`,
      component: () => import(/* */ './Pages/Routes/Route.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'new-transactions',
      path: ROUTES.NEWTRANSACTIONS,
      component: () => import(/* */ './Pages/NewTransactions/Transactions.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'transactions',
      path: ROUTES.TRANSACTIONS,
      component: () => import(/* */ './Pages/Transactions/Transactions.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'detailing',
      path: ROUTES.DETAILING,
      component: () => import(/* */ './Pages/Detailing/Detailing.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'buses',
      path: ROUTES.BUSES,
      component: () => import(/* */ './Pages/Buses/Buses.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'bus',
      path: `${ROUTES.BUSES}/:id`,
      component: () => import(/* */ './Pages/Buses/Bus.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'schedules',
      path: ROUTES.SCHEDULES,
      component: () => import(/* */ './Pages/Schedules/Schedules.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'waybills',
      path: ROUTES.WAYBILLS,
      component: () => import(/* */ './Pages/Waybills/Waybills.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'waybill',
      path: `${ROUTES.WAYBILLS}/:id`,
      component: () => import(/* */ './Pages/Waybills/Waybill.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'reports',
      path: ROUTES.REPORTS,
      component: () => import(/* */ './Pages/Reports/Reports.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'employees',
      path: ROUTES.EMPLOYEES,
      component: () => import(/* */ './Pages/Employees/Employees.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'checkbuses',
      path: ROUTES.CHECKBUSES,
      component: () => import(/* */ './Pages/Mechanics/CheckedBuses.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'checkbus',
      path: `${ROUTES.CHECKBUSES}/:id`,
      component: () => import(/* */ './Pages/Mechanics/CheckBus.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'checkdrivers',
      path: ROUTES.CHECKDRIVERS,
      component: () => import(/* */ './Pages/Mechanics/CheckedDrivers.vue'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'checkdriver',
      path: `${ROUTES.CHECKDRIVERS}/:id`,
      component: () => import(/* */ './Pages/Mechanics/CheckDriver.vue'),
      beforeEnter: ifAuthenticated
    },
  ]
});

