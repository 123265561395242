import 'url-search-params-polyfill';
import 'url-polyfill';

/**
 * Return string for query string from object
 * @param {Object} data - object of key values
 */
export const createQueryString = data => {
  const response = Object.entries(data)
    .reduce((acc, [key, item] = [undefined, undefined]) =>
      !item ? acc : { ...acc, ...{ [key]: item } }, {})
  const filtered = new URLSearchParams(response).toString();
  return `${filtered.length && '?' || ''}${filtered}`;
};
