import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import vueRouter from './plugins/vue-router'
import vuex from './plugins/vuex'
import VueGoogleMaps from './plugins/google-map'
import router  from './routes'
import store  from './store/store'

Vue.config.productionTip = false
new Vue({
  vuetify,
  vueRouter,
  VueGoogleMaps,
  vuex,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
