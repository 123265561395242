// export const ROOT_ROUTE = '/'
// export const AUTH_ROUTE = '/auth'

// export const BUS_ROUTE = '/buses'
// export const ROUTES_ROUTE = '/routes'
// export const TRANSACTIONS_ROUTE = '/transactions'
// export const SCHEDULES_ROUTE = '/schedules'
// export const WAYBILLS_ROUTE = '/waybills'
// export const REPORTS_ROUTE = '/reports'

// export const ROUTES = new RoutesPathes();
export default new class RoutesPathes {
    get ROOT() { return '/'; }
    get AUTH() { return '/auth'; }

    get BUSES() { return '/buses'; }
    get ROUTES() { return '/routes'; }
    get SCHEDULES() { return '/schedules'; }
    get TRANSACTIONS() { return '/transactions'; }
    get DETAILING() { return '/detailing'; }
    get NEWTRANSACTIONS() { return '/new-transactions'; }
    get WAYBILLS() { return '/waybills'; }
    get REPORTS() { return '/reports'; }
    get EMPLOYEES() { return '/employees'; }
    get CHECKBUSES() { return '/checkbuses'; }
    get CHECKDRIVERS() { return '/checkdrivers'; }
}
