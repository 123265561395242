<template>
  <v-app class="bg-image">


    <v-app-bar :color="color" dense dark app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ getCompany.fullName ? getCompany.fullName + ' - ' : '' }} Кабинет
        перевозчика</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="exit" v-if="isAuth">
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- <v-app-bar app color="primary" dark>
      <v-row no-gutters>
        <v-col cols=12>
          sdf
        </v-col>
        <v-col>
          sdf
        </v-col>
      </v-row>
      <div class="d-flex align-center">
        <logo />
      </div>
      <v-spacer></v-spacer>
      <v-btn text @click="exit" v-if="isAuth">
        <span class="mr-2">Выйти</span>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar> -->

    <v-main>
      <v-navigation-drawer v-model="drawer" :color="color" :expand-on-hover="expandOnHover" :mini-variant="false"
        :right="right" :permanent="permanent" absolute dark v-if="isDrawerDisplay && isAuth">
        <v-list dense nav class="py-0 offset-top">
          <v-list-item v-for="item in items" :key="item.title" :to="item.route" @click="drawer = !isDrawerDisplay" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div :class="{ content: true }">
        <v-container>
          <router-view></router-view>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>
// import Logo from "./components/Logo";
import { EXIT_USER } from "./store/modules/user";
import { EXIT_COMPANY, GET_COMPANY } from "./store/modules/company";
import ROUTES from "./constants/routes";

export default {
  components: {
    // Logo,
  },
  data: () => ({
    drawer: true,
    items: [
      { title: "Наш автопарк", icon: "mdi-bus-multiple", route: ROUTES.BUSES },
      {
        title: "Маршруты",
        icon: "mdi-arrow-decision-outline",
        route: ROUTES.ROUTES,
      },
      {
        title: "Графики движения",
        icon: "mdi-clock-outline",
        route: ROUTES.SCHEDULES,
      },
      {
        title: "Список транзакций",
        icon: "mdi-cash-100",
        route: ROUTES.TRANSACTIONS,
      },
      {
        title: "Детализация",
        icon: "mdi-table",
        route: ROUTES.DETAILING,
      },
      // {
      // {
      //   title: "Наряды",
      //   icon: "mdi-text-box-multiple-outline",
      //   route: ROUTES.WAYBILLS,
      // },
      // {
      //   title: "Сотрудники",
      //   icon: "mdi-card-account-details-outline",
      //   route: ROUTES.EMPLOYEES,
      // },
      // {
      //   title: "Допуск ТС",
      //   icon: "mdi-bus-clock",
      //   route: ROUTES.CHECKBUSES,
      // },
      // {
      //   title: "Допуск водителей",
      //   icon: "mdi-account-clock-outline",
      //   route: ROUTES.CHECKDRIVERS,
      // },
      {
        title: "Отчёты",
        icon: "mdi-chart-line",
        route: ROUTES.REPORTS,
      },
    ],
    color: "primary",
    colors: ["primary", "blue", "success", "red", "teal"],
    right: false,
    permanent: true,
    miniVariant: false,
    expandOnHover: true,
    background: false,
    showDrawer: false
  }),
  computed: {
    isAuth: (c) => c.$store.getters.isAuthenticated,
    getCompany: (c) => c.$store.getters.getCompany,
    isDrawerDisplay: (c) => c.drawer || (!c.$vuetify.breakpoint.sm && !c.$vuetify.breakpoint.xs)

  },
  async created() {
    if (localStorage.getItem('ACCESS_TOKEN')) {
      console.log("access")
      this.$store.dispatch(GET_COMPANY).then(response => {
        console.log("company", response)
      })
    }
  },
  methods: {
    exit() {
      this.$store.dispatch(EXIT_USER);
      this.$store.dispatch(EXIT_COMPANY);
      this.$router.push(ROUTES.AUTH);
    },
  },
};
</script>
<style lang="scss" scoped>
.offset-top {
  margin-top: 40px;
}

.offset-left {
  margin-left: 56px;
}

.bg-image {
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 1) 0%,
      rgb(221, 243, 224) 82%,
      rgba(255, 255, 255, 1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1) !important;
}
</style>
